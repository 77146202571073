













































import { validateQuestion } from './question-validation.util'

export default {

  components: { 
    'a-answer': () => import('./a-answer.vue')
  },

  props: {
    questions: { required: true },
    indexQ: { required: true },
  },

  data() {
    return { 
      validationErrors: [] 
    }
  },

  methods: { 
    saveQuestion() {
      this.validationErrors = validateQuestion(this.questions[this.indexQ])
      const isValid = this.validationErrors == 0
      this.$emit('save', isValid)
      return isValid
    },

    validateState(ref) {
      if (
          this.veeFields[ref] &&
          (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
  }
}
