export function validateQuestion(question) {
  const alerts: Array<{ text: string; visible: boolean }> = []
  if (question.name.length < 1) {
    alerts.push({text: 'Zadajte otázku!', visible: true})
  }

  if (question.type == 'choices') {
    if (question.choices.length < 2) {
      alerts.push({ text: 'Typ otázky "Možnosti" musí mať vyplnené minimálne 2 odpovede!', visible: true})
    }

    const correctAnswersCount = question.choices.filter(function (e) {
      return e.is_correct === true
    }).length

    if (correctAnswersCount < 1) {
      alerts.push({
        text:'Musíte zvoliť minimálne jednu správnu odpoveď!', 
        visible: true
      })
    } else if (correctAnswersCount != 1) {
      alerts.push({
        text:'Môžete zvoliť len jednu správnu odpoveď! (alebo zmeniť typ otázky na multiselect)', 
        visible: true
      })
    }
  } else if (question.type == 'multiselect') {
    const correctAnswersCount = question.choices.filter(function (e) {
      return e.is_correct === true
    }).length

    if (correctAnswersCount < 2) {
      alerts.push({text:'Zvoľte aspoň dve správne odpovede!', visible: true})
    }
  }

  return alerts
}
